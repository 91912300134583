@font-face {
  font-family: "kollektif";
  src: url("./fonts/kollektif.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "kollektif", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f97d45;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
